<i18n>
	{
		"en": {
			"confirmPayment": "Confirm Payment",
			"confirm": "Confirm",
			"cancel": "Cancel"
		},
		"np": {
		"confirmPayment": "भुक्तानी पुष्टि गर्नुहोस्",
		"confirm": "पुष्टि गर्नुहोस्",
		"रद्द गर्नुहोस्": "रद्द गर्नुहोस्"
		}
	}
</i18n>
<template lang="pug">
	b-modal( no-close-on-backdrop  hide-header hide-footer centered ref='pay-modal' no-close-on-esc)
		.wallet-form-box
			.card-body.m-2(v-if='!loading')
				.mb-4.font-weight-bold.border-bottom {{ $t("confirmPayment") }}
				form(@submit.prevent ='verifyPin')
					payment-preview(:preview='true' :preview-details='previewDetails')
					input.mt-3.form-control#pin(type='text'  v-model='pin' placeholder='Enter PIN code'  name='1414' minlength='6' maxlength='6' required ref='inputPin' v-show='!kycNotVerified')
					//small.text.text-muted.pl-1  PIN helps to maintain level of security in transactions.
					.show-message.mt-2.mb-1(v-if='message') {{message}}
					.mt-3
						button.btn.btn-primary.btn-text(:disabled='kycNotVerified || pinLoading')
							.spinner-border.spinner-border-sm(role='status' v-if='pinLoading'  aria-hidden="true")
							.span(v-else) {{ $t("confirm") }}
						button.ml-2.btn.btn-text(type='button' @click='pinVerification(null)') {{ $t("cancel") }}
						div.mt-2
							small.text(v-if='kycNotVerified') ** As KYC is not verified, you cannot perform transaction above Rs. 5000. **
			.card-loader(v-else)
</template>

<script type="text/javascript">
import PaymentPreview from "@/components/PaymentPreview";
import api from "@/helpers/api";
import "@/assets/loading.scss";
import axios from "@/axios";
export default {
	name: "pin-confirm",
	components: { PaymentPreview },
	data: function () {
		return {
			pin: "",
			kycNotVerified: true,
			loading: true,
			message: "",
			pinLoading: false,
		};
	},
	async mounted() {
		this.$refs["pay-modal"].show();
		const checkKYC = await api.isKycNotVerified();
		if (
			parseFloat(this.previewDetails["Total Amount to Pay"]?.split("Rs. ")[1]) > 5000 &&
			(checkKYC.notFilled || checkKYC.inProgress)
		) {
			this.kycNotVerified = true;
		} else {
			this.kycNotVerified = false;
		}
		this.loading = false;
	},
	props: {
		previewDetails: Object,
		pinVerification: Function,
	},
	methods: {
		async verifyPin() {
			this.pinLoading = true;
			const res = await axios.post("api/v1/walletpin/check", { pin: this.pin });
			this.pinLoading = false;
			if (res.data.code === 1) this.pinVerification(this.pin);
			else this.message = res.data.message;
		},
	},
};
</script>

<style scoped>
#pin {
	letter-spacing: 10px;
	text-align: center;
	font-weight: bold;
	-webkit-text-security: disc !important;
}
</style>
