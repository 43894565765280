<template lang='pug'>
	div
		transition(name="fade" mode="out-in")
			div(v-if='preview')
				div.shadow-none.bg-light.p-3
					.row(v-for='(value,key) in previewDetails' :key='key')
						.col-md-6.font-weight-bold {{key}}
						.col-md-6 {{value}}
</template>
<script>
export default {
	props: {
		preview: Boolean,
		previewDetails: Object
	}
};
</script>