<i18n>
  {
    "en": {
      "withdraw": "Withdraw Fund",
      "selectBank": "Select Bank",
      "enterAccountName": "Enter Account Name",
      "enterAccNum": "Enter Account Number",
      "amount": "Amount to Withdraw (Rs.)",
      "purpose": "Payment Purpose",
      "confirm": "Confirm"
    },
    "np": {
       "withdraw": "कोष निकाल्नुहोस्",
       "selectBank": "बैंक चयन गर्नुहोस्",
       "enterAccountName": "खाताको नाम राख्नुहोस्",
       "enterAccNum": "खाता नम्बर राख्नुहोस्",
       "amount": "झट्ने रकम (रु.)",
       "purpose": "भुक्तानी उद्देश्य",
       "confirm": "पुष्टि गर्नुहोस्"
     }
  }
</i18n>

<template lang="pug">
	.container
		.wallet-form-box.card
			.card-body
				.row
					.col-sm-10.col-lg-6
						h4 {{$t("withdraw")}}
						.show-message(v-if='message') {{message}}
						transition(name='fade' mode='out-in')
							form(ref='formRef' @submit.prevent='makePayment')
								.form-group
									label {{$t("selectBank")}}
									select.form-control(v-model="withdraw.bank" required)
										option(:value="null" disabled) {{$t("selectBank")}}
										option(v-for="bank in banks" :key="bank.bankCode" :value="bank.bankCode") {{bank.bankName}}
								.form-group
									label {{$t("enterAccountName")}}
									input.form-control(name='bankAccName' v-model='withdraw.name' required :placeholder='$t("enterAccountName")')
								
								.form-group
									label {{$t("enterAccNum")}}
									input.form-control(name='bankAccNumber' v-model='withdraw.accountNumber' required :placeholder='$t("enterAccNum")')
								.form-group
									label {{ $t("amount") }}
									input.form-control(type='number' min='0' max='100000' v-model='withdraw.amount' :placeholder="$t('amount')" required)
								.form-group
									label {{ $t("purpose") }}
									input.form-control(v-model="withdraw.remarks" :placeholder="$t('purpose')")
								button.btn.btn-primary(:disabled="loading") {{$t("confirm")}}
								div(v-if='paymentModal')
									pin-confirm(:previewDetails='previewDetails' :pinVerification='verifyPin')
		loading-spinner( :loading="loading")
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import PinConfirm from "@/components/PinConfirm";
import payment from "@/mixins/payment";
import axios from "@/axios";
export default {
  mixins: [payment],
  name: "withdraw-fund",
  components: { LoadingSpinner, PinConfirm },
  data() {
    return {
      withdraw: {
        accountNumber: "",
        amount: "",
        serviceCharge: 0,
        name: "",
        bank: null,
        totalAmount: "",
      },
      message: "",
      banks: [],
      loading: false,
      otp: "",
    };
  },
  computed: {
    previewDetails() {
      return {
        "Account Name": this.withdraw.accountName,
        "Account Number": this.withdraw.accountNumber,
        "Withdraw Amount": "Rs. " + this.withdraw.amount,
        "Service Charge": "Rs. " + this.withdraw.serviceCharge,
        "Total Amount to Pay": "Rs. " + this.withdraw.totalAmount,
      };
    },
  },
  async mounted() {
    let res = await axios.get("api/v1/withdraw/nepalPayment/banks/list");
    if (res.data.data) {
      this.banks = res.data.data.bank;
    }
  },
  methods: {
    resetInfo() {
      this.withdraw.accountNumber = "";
      this.withdraw.amount = "";
      this.withdraw.name = "";
      this.withdraw.bank = null;
    },

    verifyPin(valid) {
      this.withdraw.pin = valid;
      this.pinVerification(this.makePayment, valid);
    },
    async makePayment() {
      if (!this.pinVerified) {
        this.withdraw.bankCode = this.withdraw.bank;
        this.withdraw.accountName = this.withdraw.name;
        this.loading = true;
        try {
          const verifyResponse = await axios.post(
            "api/v1/withdraw/nepalPayment/confirmation",
            this.withdraw
          );
          this.loading = false;
          if (verifyResponse.data.code === 1) {
            this.withdraw.totalAmount = verifyResponse.data.data.data.totalAmount;
            this.withdraw.serviceCharge = verifyResponse.data.data.data.commissionCharge;
            this.paymentModal = true;
          } else {
            this.message =
              "Provided credentials could not be verified. Please try again.";
          }
        } catch (er) {
          this.loading = false;
          this.message = "Provided credentials could not be verified. Please try again.";
        }
        return;
      }
      this.loading = true;

      const loadResponse = await axios.post("api/v4/withdraw/payment", this.withdraw);
      if (loadResponse.data) {
        if (loadResponse.data.status == 1) {
          this.resetInfo();
          this.$store.dispatch("UPDATE_BALANCE");
          this.message = loadResponse.data.message;
        } else {
          this.message = loadResponse.data?.message;
        }
      } else {
        this.message = "Something went wrong with the payment";
      }
      this.loading = false;
    },
  },
};
</script>
